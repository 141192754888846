import moment from "moment";
import React from "react";
import ThumbsDown from "../assets/ThumbsDown.png";
import ThumbsUp from "../assets/ThumbsUp.png";
import CrossIcon from "../assets/cross.svg";
import ExclamationIcon from "../assets/exclamation.svg";

const getIconForStatus = (status) => {
  switch (status) {
    case "false":
      return <img src={ThumbsDown} alt="Thumbs Down" />;
    case "true":
      return <img src={ThumbsUp} alt="Thumbs Up" />;
    case "ex":
      return <img src={ExclamationIcon} alt="Exclamation" />;
    case "cross":
      return <img src={CrossIcon} alt="Cross" />;
    case "-":
      return "-";
    default:
      return "-";
  }
};

const getStationName = (stationNumber) => {
  return stationNumber == "-"
    ? "-"
    : stationNumber == "0"
    ? "IWM"
    : `Station ${stationNumber}`;
};

const formatWeldStartTime = (
  date,
  time = false,
  onlyTime = false,
  isYear = false
) => {
  if (date == "" || isNaN(date)) {
    return "-";
  }
  if (isYear) {
    return moment(1000000 * date).format("MMM-DD,YYYY, HH:mm:ss");
  }

  if (onlyTime) {
    return moment(1000000 * date).format("HH:mm:ss");
  }

  return time
    ? moment(1000000 * date).format("MMM-DD, HH:mm:ss")
    : moment(1000000 * date).format("MMM DD, YYYY");
};

const getPassNamePassZone = ({
  avgTlogs,
  station_number,
  pass_name,
  tableLimitData,
  job_number,
  weldNumber,
}) => {
  if (weldNumber == "Unknown") {
    return "-";
  }
  if (station_number != "0") {
    let passNamesExtracted = avgTlogs?.map((ite) => ite[pass_name]);

    if (passNamesExtracted?.length != avgTlogs?.length) {
      return "!";
    }
    if (tableLimitData.length == 0) {
      return "-";
    }

    let allPassNames = [];

    if (job_number) {
      allPassNames = avgTlogs?.filter((ite) => ite.job_number == job_number);
    } else {
      allPassNames = avgTlogs;
    }

    allPassNames = allPassNames?.map((tlog) => {
      return tableLimitData?.find(
        (tableLimit) =>
          tableLimit.zone_name == tlog[pass_name] &&
          tableLimit.job_number == job_number
      );
    });

    let uniquePassNames = [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...new Set([...allPassNames?.map((ite) => ite?.pass_name)]),
    ];

    console.log(uniquePassNames, "uniquePassNames = getPassNamePassZone", {
      avgTlogs,
      station_number,
      pass_name,
      tableLimitData,
      job_number,
      weldNumber,
      passNamesExtracted,
      allPassNames,
    });
    if (uniquePassNames?.length > 1 || uniquePassNames?.length == 0) {
      return "!";
    } else {
      return uniquePassNames?.[0];
    }
  } else {
    return "Root IWM";
  }
};

const getPassNameSingleTlog = ({
  tlogsSingle,
  station_number,
  tableLimitData,
  weld_number,
}) => {
  if (weld_number == "Unknown") {
    return "-";
  }
  if (station_number == "0") {
    return "Root IWM";
  }

  if (tableLimitData.length == 0) {
    return "-";
  }

  console.log(
    {
      tlogsSingle,
      isNot: !tlogsSingle?.trail_pass_name && !tlogsSingle?.lead_pass_name,
    },
    "tlogsSingle-tlogsSingle"
  );

  if (!tlogsSingle?.trail_pass_name && !tlogsSingle?.lead_pass_name) {
    return "!";
  }

  let passNameExtracted =
    tlogsSingle?.trail_pass_name || tlogsSingle?.lead_pass_name;

  let allPassNames = [];

  if (tlogsSingle?.job_number) {
    allPassNames = tableLimitData?.filter(
      (tableLimit) => tableLimit.job_number == tlogsSingle?.job_number
    );
  } else {
    allPassNames = tableLimitData;
  }

  allPassNames = allPassNames?.filter(
    (tableLimit) => tableLimit.zone_name == passNameExtracted
  );

  allPassNames = allPassNames?.map((ite) => ite.pass_name);

  let uniquePassNames = [...new Set([...allPassNames])];

  console.log(
    {
      uniquePassNames,
      job_number: tlogsSingle?.job_number,
      passNameExtracted,
      tlogsSingle,
    },
    "job_numberjob_numberjob_number ==> tlog"
  );

  if (uniquePassNames?.length == 0) {
    return "!";
  } else {
    return uniquePassNames?.[0];
  }
};

const allParamsValues = {
  arcCurrent: "Current",
  arcVolts: "Volts",
  frequency: "Frequency",
  target: "Target",
  horizontalBias: "Horizontal Bias",
  travelSpeed: "Travel Speed",
  wireSpeed: "Wire Speed",
  heat: "Heat",
  trueEnergy: "True Energy",
  oscillationWidth: "Oscillation Width",
  totalWireConsumed: "Total Wire Consumed",
  bandDiameter: "Band Diameter",
  wireDiameter: "Wire Diameter",
  loggingDistance: "Logging Distance",
};

const defaultParamsValues = [
  "Current",
  "Volts",
  "Wire Speed",
  "Travel Speed",
  "Oscillation Width",
];

const redCircleAvailableForColumns = [
  "Heat",
  "Volts",
  "Current",
  "Wire Speed",
  "Travel Speed",
  "Calculated Heat",
  "Oscillation Width",
];

const isColumnEnabledForRedCircle = ({
  weldParamsShowColumns,
  currentColumn,
  jobNumber,
}) => {
  let jobNumberExists = weldParamsShowColumns?.find(
    (ite) => ite.job_number == jobNumber
  );

  console.log(
    {
      jobNumber,
      jobNumberExists,
      currentColumn,
      weldParamsShowColumns,
    },
    "currentColumn - pass view"
  );
  if (redCircleAvailableForColumns.includes(currentColumn)) {
    if (jobNumberExists) {
      if (jobNumberExists.enabledKeys.includes(currentColumn)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const sampleReportColumns = {
  switch: "Avg",
  params: [
    "Arc Current",
    "Arc Volts",
    "Frequency",
    "Target",
    "Horizontal Bias",
    "Travel Speed",
    "Wire Speed",
    "Heat",
    "True Energy",
    "Oscillation Width",
    "Total Wire Consumed",
    "Band Diameter",
    "Wire Diameter",
    "Logging Distance",
  ],
  units: {
    ArcCurrent: "A",
    ArcVolts: "V",
    "Travel Speed": "in/min",
    "True Energy": "kJ/in",
    Heat: "kJ/in",
    "Wire Speed": "in/min",
    "Oscillation Width": "in",
    Frequency: "cyc/m",
  },
};

const logDataHelperFunctions = {
  getIconForStatus,
  getStationName,
  formatWeldStartTime,
  getPassNamePassZone,
  getPassNameSingleTlog,
  allParamsValues,
  sampleReportColumns,
  defaultParamsValues,
  redCircleAvailableForColumns,
  isColumnEnabledForRedCircle,
};

export { logDataHelperFunctions };
