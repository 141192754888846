import { convertValue, reportColumnEnums } from "../unit-conversion";

// let tlogInput = {
//   sl_no: 13,
//   status: "-",
//   event: "777777777777777777777777_259221",
//   time: "19:37:10",
//   tilt: "27",
//   pass: "-",
//   zone: "CS",
//   distance: "9.828",
//   travelSpeed: "23.0",
//   volts: "25.02",
//   current: "337.4",
//   wireSpeed: "330.1",
//   oscillationWidth: "0.13",
//   target: "25",
//   horizontalBias: "0",
//   frequency: "200",
//   totalWireConsumed: "0.000",
//   trueEnergy: "22.0",
//   heat: "22.02",
// };

const mapTlogWithUnitConversion = (tlogInput, reportColumns) => {
  //   let reportColumns = {
  //     switch: "All",
  //     params: ["Arc Current", "Arc Volts", "Travel Speed", "Wire Speed", "Heat"],
  //     units: {
  //       ArcCurrent: "A",
  //       ArcVolts: "V",
  //       "Travel Speed": "in/min",
  //       "True Energy": "kJ/in",
  //       Heat: "kJ/in",
  //       "Wire Speed": "in/min",
  //       "Oscillation Width": "in",
  //       Frequency: "cyc/min",
  //       Target: "A",
  //       "Horizontal Bias": "A",
  //       "Total Wire Consumed": "in",
  //       "Band Diameter": "in",
  //       "Wire Diameter": "in",
  //       "Logging Distance": "in",
  //       Distance: "in",
  //     },
  //   };

  tlogInput.distance = convertValue(
    tlogInput.distance,
    reportColumns?.units?.Distance
  );
  tlogInput.travelSpeed = convertValue(
    tlogInput.travelSpeed,
    reportColumns?.units?.["Travel Speed"]
  );
  tlogInput.volts = convertValue(
    tlogInput.volts,
    reportColumns?.units?.ArcVolts
  );
  tlogInput.current = convertValue(
    tlogInput.current,
    reportColumns?.units?.ArcCurrent
  );
  tlogInput.wireSpeed = convertValue(
    tlogInput.wireSpeed,
    reportColumns?.units?.["Wire Speed"]
  );
  tlogInput.oscillationWidth = convertValue(
    tlogInput.oscillationWidth,
    reportColumns?.units?.["Oscillation Width"]
  );

  tlogInput.target = convertValue(
    tlogInput.target,
    reportColumns?.units?.Target
  );

  tlogInput.horizontalBias = convertValue(
    tlogInput.horizontalBias,
    reportColumns?.units?.["Horizontal Bias"]
  );

  tlogInput.frequency = convertValue(
    tlogInput.frequency,
    reportColumns?.units?.Frequency
  );

  tlogInput.totalWireConsumed = convertValue(
    tlogInput.totalWireConsumed,
    reportColumns?.units?.["Total Wire Consumed"]
  );

  tlogInput.trueEnergy = convertValue(
    tlogInput.trueEnergy,
    reportColumns?.units?.["True Energy"]
  );

  tlogInput.heat = convertValue(tlogInput.heat, reportColumns?.units?.Heat);

  return tlogInput;
};

const mappingColumnTableLimit = {
  volts: "volts",
  current: "current",
  wireSpeed: "wire_speed",
  oscillationWidth: "oscillation_width",
  trueEnergy: "heat",
  heat: "calculated_heat",
  travelSpeed: "travel_speed",
};

const rangeCalculation = (value, column, reportColumns) => {
  let reverseMappingToReportColumns = {
    volts: "ArcVolts",
    current: "ArcCurrent",
    wire_speed: "Wire Speed",
    oscillation_width: "Oscillation Width",
    heat: "True Energy",
    calculated_heat: "Heat",
    travel_speed: "Travel Speed",
  };

  return convertValue(
    value,
    reportColumns?.units?.[reverseMappingToReportColumns?.[column]] || ""
  );
};

const returnUnitBasedOnColumns = (column, reportColumns) => {
  console.log(reportColumns, "reportColumns - returnUnitBasedOnColumns");
  switch (column) {
    case reportColumnEnums.LoggingDistance:
    case reportColumnEnums.WireDiameter:
    case reportColumnEnums.Distance:
    case reportColumnEnums.BandDiameter: {
      return reportColumns?.units?.[column] || "in";
    }
  }
};

const mapTlogColumns = (inputColumns, reportColumns, hasData) => {
  const columnsMapping = {
    CURRENT_A: "current",
    VOLT_V: "volt",
    TRAVEL_SPEED: "travelSpeed",
    TRUE_ENERGY: "trueEnergy",
    DISTANCE: "distance",
    HEAT: "heat",
    WIRE_SPEED: "wireSpeed",
    OSCILLATION_WIDTH: "oscillationWidth",
    FREQUENCY: "frequency",
    TARGET: "target",
    HORIZONTAL_BIAS: "horizontalBias",
    TOTAL_WIRE_CONSUMED: "totalWireConsumed",
  };

  return inputColumns?.map((ite) => {
    let changeKeys = [
      columnsMapping.CURRENT_A,
      columnsMapping.VOLT_V,
      columnsMapping.TRAVEL_SPEED,
      columnsMapping.DISTANCE,
      columnsMapping.TRUE_ENERGY,
      columnsMapping.HEAT,
      columnsMapping.WIRE_SPEED,
      columnsMapping.OSCILLATION_WIDTH,
      columnsMapping.FREQUENCY,
      columnsMapping.TARGET,
      columnsMapping.HORIZONTAL_BIAS,
      columnsMapping.TOTAL_WIRE_CONSUMED,
    ];

    if (changeKeys.includes(ite.key)) {
      switch (ite.key) {
        case columnsMapping.CURRENT_A: {
          return {
            ...ite,
            title: `Current (${
              hasData ? "A" : reportColumns?.units?.ArcCurrent || "A"
            })`,
          };
        }
        case columnsMapping.VOLT_V: {
          return {
            ...ite,
            title: `Volt (${
              hasData ? "V" : reportColumns?.units?.ArcVolts || "V"
            })`,
          };
        }

        case columnsMapping.TRAVEL_SPEED: {
          return {
            ...ite,
            title: `Travel Speed (${
              hasData
                ? "in/min"
                : reportColumns?.units?.["Travel Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.TRUE_ENERGY: {
          return {
            ...ite,
            title: `True Energy (${
              hasData
                ? "KJ/in"
                : reportColumns?.units?.["True Energy"] || "KJ/in"
            })`,
          };
        }

        case columnsMapping.HEAT: {
          return {
            ...ite,
            title: `Heat (${
              hasData ? "KJ/in" : reportColumns?.units?.Heat || "KJ/in"
            })`,
          };
        }

        case columnsMapping.WIRE_SPEED: {
          return {
            ...ite,
            title: `Wire Speed (${
              hasData
                ? "in/min"
                : reportColumns?.units?.["Wire Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.OSCILLATION_WIDTH: {
          return {
            ...ite,
            title: `Oscillation Width (${
              hasData
                ? "in"
                : reportColumns?.units?.["Oscillation Width"] || "in"
            })`,
          };
        }

        case columnsMapping.FREQUENCY: {
          return {
            ...ite,
            title: `Frequency (${
              hasData ? "cyc/min" : reportColumns?.units?.Frequency || "cyc/min"
            })`,
          };
        }

        case columnsMapping.TARGET: {
          return {
            ...ite,
            title: `Target (${
              hasData ? "A" : reportColumns?.units?.Target || "A"
            })`,
          };
        }

        case columnsMapping.HORIZONTAL_BIAS: {
          return {
            ...ite,
            title: `Horizontal Bias (${
              hasData ? "A" : reportColumns?.units?.["Horizontal Bias"] || "A"
            })`,
          };
        }

        case columnsMapping.TOTAL_WIRE_CONSUMED: {
          return {
            ...ite,
            title: `Total Wire Consumed (${
              hasData
                ? "in"
                : reportColumns?.units?.["Total Wire Consumed"] || "in"
            })`,
          };
        }

        case columnsMapping.DISTANCE: {
          return {
            ...ite,
            title: `Distance (${
              hasData ? "in" : reportColumns?.units?.["Distance"] || "in"
            })`,
          };
        }
      }
    } else {
      return ite;
    }
  });
};

export const tlogHelper = {
  mapTlogWithUnitConversion,
  mapTlogColumns,
  mappingColumnTableLimit,
  rangeCalculation,
  returnUnitBasedOnColumns,
};
