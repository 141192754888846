import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  GetPqrReportLink,
  setPqrDataList,
  setReportGeneratedInfoDataList,
  startPqrDataLoader,
  stopPqrDataLoader,
} from "./actions";
import {
  FETCH_PQRDATA_REPORT_LINK_SAGA,
  FETCH_PQRDATA_REPORT_SAGA,
  FETCH_PQRDATA_SAGA,
  POST_EXCLUDED_SAGA,
  SET_REPORT_GENERATED_INFO_DATA_LIST,
} from "./types";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* fetchPqrDataSaga(action) {
  try {
    const {
      project_id,
      weld_numbers,
      project_name,
      boundary,
      items,
      columns_add,
      excluded_tlogs,
    } = action.payload;

    if (!(project_id && project_name)) {
      yield put(stopPqrDataLoader());

      return;
    }
    const url = `${BASE_URL}/v1/exposed/pass-view`; // Keep the base URL

    yield put(startPqrDataLoader());

    // Create the body object
    const body = {
      ...(project_id && { project_id }),
      ...(weld_numbers && { weld_numbers }),
      ...(project_name && { project_name }),
      ...(boundary && { boundary }),
      ...(items && { items }),
      ...(columns_add && { columns_add }),
      ...(excluded_tlogs && { excluded_tlogs }),
    };

    const res = yield fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body), // Convert the body object to a JSON string
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb(response?.data);
        }
        yield put(setPqrDataList(response?.data));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopPqrDataLoader());
  } catch (error) {
    console.log(error);
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    if (typeof action.error === "function") {
      action.error();
    }
    yield put(stopPqrDataLoader());
  }
}

function* exlcudeTlogSaga(action) {
  try {
    const { project_id, weld_numbers, project_name, boundary, excluded_tlogs } =
      action.payload;

    if (!(project_id && project_name)) {
      yield put(stopPqrDataLoader());

      return;
    }
    const url = `${BASE_URL}/v1/exposed/exclude-tlogs`; // Keep the base URL

    yield put(startPqrDataLoader());

    // Create the body object
    const body = {
      ...(project_id && { project_id }),
      ...(weld_numbers && { weld_numbers }),
      ...(project_name && { project_name }),
      ...(boundary && { boundary }),
      ...(excluded_tlogs && { excluded_tlogs }),
    };

    const res = yield fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body), // Convert the body object to a JSON string
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb(response?.data);
        }
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopPqrDataLoader());
  } catch (error) {
    console.log(error);
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    if (typeof action.error === "function") {
      action.error();
    }
    yield put(stopPqrDataLoader());
  }
}
function* fetchPqrReportDataSaga(action) {
  try {
    yield put(startPqrDataLoader());

    // eslint-disable-next-line prettier/prettier
    const {
      project_id,
      weld_numbers,
      project_name,
      boundary,
      email_address,
      items,
      columns_add,
      excluded_tlogs,
    } = action.payload;

    const url = `${BASE_URL}/v1/exposed/wps-report`;

    const body = {
      ...(project_id && { project_id }),
      ...(weld_numbers && { weld_numbers }),
      ...(project_name && { project_name }),
      ...(boundary && { boundary }),
      ...(email_address && { email_address }),
      ...(items && { items }),
      ...(columns_add && { columns_add }),
      ...(excluded_tlogs && { excluded_tlogs }),
    };

    // Make the POST request with the body
    const res = yield fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(body), // Convert the body object to a JSON string
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb();
        }
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        if (typeof action.error === "function") {
          action.error();
        }
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
        if (typeof action.error === "function") {
          action.error();
        }
    }

    yield put(stopPqrDataLoader());
  } catch (error) {
    // console.log(error);
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    yield put(stopPqrDataLoader());
  }
}

function* fetchPqrReportLinkSaga(action) {
  try {
    const {
      project_id,
      shouldExecuteStopLoader = true,
      weld_numbers,
      project_name,
      boundary,
    } = action.payload;

    if (!(project_id && project_name)) {
      yield put(stopPqrDataLoader());

      return;
    }
    // Use the base URL without search parameters
    const url = new URL(`${BASE_URL}/v1/exposed/fetch-pqr-report-link`);
    if (project_id) url.searchParams.append("project_id", project_id);
    if (project_name) url.searchParams.append("project_name", project_name);
    if (weld_numbers) url.searchParams.append("weld_numbers", weld_numbers);
    if (boundary) url.searchParams.append("boundary", boundary);

    const res = yield fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        if (typeof action.cb === "function") {
          action.cb(response?.data);
        }
        yield put(GetPqrReportLink(response?.data));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        if (typeof action.error === "function") {
          action.error();
        }
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
        if (typeof action.error === "function") {
          action.error();
        }
    }

    if (shouldExecuteStopLoader) {
      yield put(stopPqrDataLoader());
    }
  } catch (error) {
    console.log(error);
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });

    if (typeof action.error === "function") {
      action.error();
    }
    yield put(stopPqrDataLoader());
  }
}

function* fetchReportGeneratedInfoDataSaga(action) {
  try {
    yield put(startPqrDataLoader());

    const { project_id, project_name } = action.payload;

    if (!(project_id && project_name)) {
      yield put(stopPqrDataLoader());

      return;
    }
    const url = new URL(`${BASE_URL}/v1/exposed/report-generated-info`);
    if (project_id) url.searchParams.append("project_id", project_id);
    if (project_name) url.searchParams.append("project_name", project_name);

    const res = yield fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        yield put(setReportGeneratedInfoDataList(response?.data));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopPqrDataLoader());
  } catch (error) {
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    yield put(stopPqrDataLoader());
  }
}

function* pqrdataWatcher() {
  yield takeLatest(FETCH_PQRDATA_SAGA, fetchPqrDataSaga);
  yield takeLatest(FETCH_PQRDATA_REPORT_SAGA, fetchPqrReportDataSaga);
  yield takeLatest(FETCH_PQRDATA_REPORT_LINK_SAGA, fetchPqrReportLinkSaga);
  yield takeLatest(
    SET_REPORT_GENERATED_INFO_DATA_LIST,
    fetchReportGeneratedInfoDataSaga
  );
  yield takeLatest(POST_EXCLUDED_SAGA, exlcudeTlogSaga);
}

function* pqrdataSagas() {
  yield all([call(pqrdataWatcher)]);
}

export default pqrdataSagas;
