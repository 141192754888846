const UNITS = {
  IN_MIN: "in/min",
  IN: "in",
  CM: "cm",
  MM: "mm",
  CM_MIN: "cm/min",
  MM_MIN: "mm/min",
  LB: "lb",
  KG: "kg",
  KJ_IN: "kJ/in",
  KJ_CM: "kJ/cm",
  KJ_MM: "kJ/mm",
};

export const reportColumnEnums = {
  ArcCurrent: "ArcCurrent",
  ArcVolts: "ArcVolts",
  TravelSpeed: "Travel Speed",
  TrueEnergy: "True Energy",
  Heat: "Heat",
  WireSpeed: "Wire Speed",
  OscillationWidth: "Oscillation Width",
  Frequency: "Frequency",
  Target: "Target",
  HorizontalBias: "Horizontal Bias",
  TotalWireConsumed: "Total Wire Consumed",
  BandDiameter: "Band Diameter",
  WireDiameter: "Wire Diameter",
  LoggingDistance: "Logging Distance",
  Distance: "Distance",
};

export const convertValue = (value, toUnits = "") => {
  let isColorIncluded = value?.toString()?.includes("-color");
  value = value?.toString()?.includes("-color")
    ? value?.toString().replace("-color", "")
    : value;

  const parsedValue = parseFloat(value);
  let convertedValue = value;

  if (!isNaN(parsedValue)) {
    switch (toUnits) {
      // case UNITS.IN_MIN:
      //   convertedValue
      //   break;
      // case UNITS.IN:
      //   convertedValue = parsedValue / 2.54;
      //   break;
      case UNITS.CM:
        convertedValue = parsedValue * 2.54;
        break;
      case UNITS.MM:
        convertedValue = parsedValue * 25.4;
        break;
      case UNITS.CM_MIN:
        convertedValue = parsedValue * 2.54;
        break;
      case UNITS.MM_MIN:
        convertedValue = parsedValue * 25.4;
        break;
      case UNITS.LB:
        convertedValue = parsedValue * 2.205;
        break;
      case UNITS.KG:
        convertedValue = parsedValue / 2.205;
        break;
      // case UNITS.KJ_IN:
      //   convertedValue = parsedValue * 2.54;
      //   break;
      case UNITS.KJ_CM:
        convertedValue = parsedValue / 2.54;
        break;
      case UNITS.KJ_MM:
        convertedValue = parsedValue / 25.4;
        break;
      default:
        break;
    }

    if (!Number.isInteger(convertedValue)) {
      convertedValue = parseFloat(convertedValue).toFixed(2);
    }

    return `${convertedValue}${isColorIncluded ? "-color" : ""}`;
  }

  return value;
};
