import { convertValue } from "../unit-conversion";

const mapZoneViewColumnTitles = (inputColumns, reportColumns) => {
  const columnsMapping = {
    CURRENT_A: "currentA",
    VOLT_V: "voltV",
    TRAVEL_SPEED: "travelSpeed",
    TRUE_ENERGY: "trueEnergy",
    HEAT: "heat",
    WIRE_SPEED: "wireSpeed",
    OSCILLATION_WIDTH: "oscillationWidth",
    FREQUENCY: "frequency",
    TARGET: "target",
    HORIZONTAL_BIAS: "horizontalBias",
    TOTAL_WIRE_CONSUMED: "totalWireConsumed",
  };

  return inputColumns?.map((ite) => {
    let changeKeys = [
      columnsMapping.CURRENT_A,
      columnsMapping.VOLT_V,
      columnsMapping.TRAVEL_SPEED,
      columnsMapping.TRUE_ENERGY,
      columnsMapping.HEAT,
      columnsMapping.WIRE_SPEED,
      columnsMapping.OSCILLATION_WIDTH,
      columnsMapping.FREQUENCY,
      columnsMapping.TARGET,
      columnsMapping.HORIZONTAL_BIAS,
      columnsMapping.TOTAL_WIRE_CONSUMED,
    ];

    if (changeKeys.includes(ite.key)) {
      switch (ite.key) {
        case columnsMapping.CURRENT_A: {
          return {
            ...ite,
            title: `Current (${reportColumns.units.ArcCurrent || "A"})`,
          };
        }
        case columnsMapping.VOLT_V: {
          return {
            ...ite,
            title: `Volt (${reportColumns.units.ArcVolts || "V"})`,
          };
        }

        case columnsMapping.TRAVEL_SPEED: {
          return {
            ...ite,
            title: `Travel Speed (${
              reportColumns.units["Travel Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.TRUE_ENERGY: {
          return {
            ...ite,
            title: `True Energy (${
              reportColumns.units["True Energy"] || "KJ/in"
            })`,
          };
        }

        case columnsMapping.HEAT: {
          return {
            ...ite,
            title: `Heat (${reportColumns.units.Heat || "KJ/in"})`,
          };
        }

        case columnsMapping.WIRE_SPEED: {
          return {
            ...ite,
            title: `Wire Speed (${
              reportColumns.units["Wire Speed"] || "in/min"
            })`,
          };
        }

        case columnsMapping.OSCILLATION_WIDTH: {
          return {
            ...ite,
            title: `Oscillation Width (${
              reportColumns.units["Oscillation Width"] || "in"
            })`,
          };
        }

        case columnsMapping.FREQUENCY: {
          return {
            ...ite,
            title: `Frequency (${reportColumns.units.Frequency || "cyc/min"})`,
          };
        }

        case columnsMapping.TARGET: {
          return {
            ...ite,
            title: `Target (${reportColumns.units.Target || "A"})`,
          };
        }

        case columnsMapping.HORIZONTAL_BIAS: {
          return {
            ...ite,
            title: `Horizontal Bias (${
              reportColumns.units["Horizontal Bias"] || "A"
            })`,
          };
        }

        case columnsMapping.TOTAL_WIRE_CONSUMED: {
          return {
            ...ite,
            title: `Total Wire Consumed (${
              reportColumns.units["Total Wire Consumed"] || "in"
            })`,
          };
        }
      }
    } else {
      return ite;
    }
  });
};

const mapperForUnitConversionZoneView = (inputItemObject, reportColumns) => {
  //current
  inputItemObject.current_low = convertValue(
    inputItemObject.current_low,
    reportColumns.units.ArcCurrent
  );
  inputItemObject.current_avg = convertValue(
    inputItemObject.current_avg,
    reportColumns.units.ArcCurrent
  );
  inputItemObject.current_high = convertValue(
    inputItemObject.current_high,
    reportColumns.units.ArcCurrent
  );

  //volt
  inputItemObject.volt_low = convertValue(
    inputItemObject.volt_low,
    reportColumns.units.ArcVolts
  );
  inputItemObject.volt_avg = convertValue(
    inputItemObject.volt_avg,
    reportColumns.units.ArcVolts
  );
  inputItemObject.volt_high = convertValue(
    inputItemObject.volt_high,
    reportColumns.units.ArcVolts
  );

  //travel speed
  inputItemObject.travel_speed_low = convertValue(
    inputItemObject.travel_speed_low,
    reportColumns.units["Travel Speed"]
  );
  inputItemObject.travel_speed_avg = convertValue(
    inputItemObject.travel_speed_avg,
    reportColumns.units["Travel Speed"]
  );
  inputItemObject.travel_speed_high = convertValue(
    inputItemObject.travel_speed_high,
    reportColumns.units["Travel Speed"]
  );

  //heat(calculated)
  inputItemObject.heat_low = convertValue(
    inputItemObject.heat_low,
    reportColumns.units.Heat
  );
  inputItemObject.heat_avg = convertValue(
    inputItemObject.heat_avg,
    reportColumns.units.Heat
  );
  inputItemObject.heat_high = convertValue(
    inputItemObject.heat_high,
    reportColumns.units.Heat
  );

  //true energy
  inputItemObject.true_energy_low = convertValue(
    inputItemObject.true_energy_low,
    reportColumns.units["True Energy"]
  );
  inputItemObject.true_energy_avg = convertValue(
    inputItemObject.true_energy_avg,
    reportColumns.units["True Energy"]
  );
  inputItemObject.true_energy_high = convertValue(
    inputItemObject.true_energy_high,
    reportColumns.units["True Energy"]
  );

  //wire speed
  inputItemObject.wire_speed_low = convertValue(
    inputItemObject.wire_speed_low,
    reportColumns.units["Wire Speed"]
  );
  inputItemObject.wire_speed_avg = convertValue(
    inputItemObject.wire_speed_avg,
    reportColumns.units["Wire Speed"]
  );
  inputItemObject.wire_speed_high = convertValue(
    inputItemObject.wire_speed_high,
    reportColumns.units["Wire Speed"]
  );

  //oscillation width
  inputItemObject.oscillation_width_low = convertValue(
    inputItemObject.oscillation_width_low,
    reportColumns.units["Oscillation Width"]
  );
  inputItemObject.oscillation_width_avg = convertValue(
    inputItemObject.oscillation_width_avg,
    reportColumns.units["Oscillation Width"]
  );
  inputItemObject.oscillation_width_high = convertValue(
    inputItemObject.oscillation_width_high,
    reportColumns.units["Oscillation Width"]
  );

  //frequency
  inputItemObject.frequency_low = convertValue(
    inputItemObject.frequency_low,
    reportColumns.units.Frequency
  );
  inputItemObject.frequency_avg = convertValue(
    inputItemObject.frequency_avg,
    reportColumns.units.Frequency
  );
  inputItemObject.frequency_high = convertValue(
    inputItemObject.frequency_high,
    reportColumns.units.Frequency
  );

  //target
  inputItemObject.target_low = convertValue(
    inputItemObject.target_low,
    reportColumns.units.Target
  );
  inputItemObject.target_avg = convertValue(
    inputItemObject.target_avg,
    reportColumns.units.Target
  );
  inputItemObject.target_high = convertValue(
    inputItemObject.target_high,
    reportColumns.units.Target
  );

  //horizontal bias
  inputItemObject.horizontal_bias_low = convertValue(
    inputItemObject.horizontal_bias_low,
    reportColumns.units["Horizontal Bias"]
  );
  inputItemObject.horizontal_bias_avg = convertValue(
    inputItemObject.horizontal_bias_avg,
    reportColumns.units["Horizontal Bias"]
  );
  inputItemObject.horizontal_bias_high = convertValue(
    inputItemObject.horizontal_bias_high,
    reportColumns.units["Horizontal Bias"]
  );

  //total wire consumed
  inputItemObject.total_wire_consumed_low = convertValue(
    inputItemObject.total_wire_consumed_low,
    reportColumns.units["Total Wire Consumed"]
  );
  inputItemObject.total_wire_consumed_avg = convertValue(
    inputItemObject.total_wire_consumed_avg,
    reportColumns.units["Total Wire Consumed"]
  );
  inputItemObject.total_wire_consumed_high = convertValue(
    inputItemObject.total_wire_consumed_high,
    reportColumns.units["Total Wire Consumed"]
  );

  return inputItemObject;
};

export const zoneViewHelper = {
  mapZoneViewColumnTitles,
  mapperForUnitConversionZoneView,
};
