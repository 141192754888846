import { DownloadOutlined, EyeOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Tag, Tooltip, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pdfdisable from "../../assets/pdf-disable.png";
import pdf from "../../assets/pdf.png";
import { FETCH_PQRDATA_REPORT_SAGA } from "../../redux/pqrReportData/types";
import EmailForm from "../Common/EmailForm";
import CommonLoader from "../Loaders/loader";

const { Option } = Select;
const PQRReportContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slogData = useSelector((state) => state.slogReducer?.slogsList);
  const reportGeneratedData = useSelector(
    (state) => state.pqrdataReducer?.reportGeneratedInfoList?.recordList
  );
  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const Loader = useSelector((state) => state.pqrdataReducer?.isPqrDataLoding);
  const history = useHistory();
  const StatusData = useSelector((state) => state.statusReducer);
  const tableLimitData = StatusData?.statusList?.allDataSet?.recordList;
  const hasJobNumbers = tableLimitData?.some((item) => item.job_number);

  const [selectedWeldNumbers, setSelectedWeldNumbers] = useState([]);

  const weldnumber = slogData.map((ite) => ite.weldNumber);

  const handleWeldNumberChange = (values) => {
    setSelectedWeldNumbers(values);
  };

  const openSelectedDiv = () => {
    history.push(`/pqr-table-data/${selectedWeldNumbers}?boundary=10`);
  };

  const handlePQRGenerayedReportClick = (weldNumber, boundary) => {
    history.push(`/pqr-table-data/${weldNumber}?boundary=${boundary}`);
  };

  const handleClose = (removedNumber) => {
    setSelectedWeldNumbers((prevNumbers) =>
      prevNumbers.filter((number) => number !== removedNumber)
    );
  };

  useEffect(() => {
    setSelectedWeldNumbers([]);
  }, [selectedProjectId, selectedProjectName]);

  const [isModalVisibletwo, setIsModalVisibletwo] = useState(false);
  const [emailFormtwo] = Form.useForm();
  const [weldNumberArray, setWeldNumberArray] = useState([]);
  const [inputBoundary, setInputBoundary] = useState(null);
  const [openShareEmailReportData, setOpenShareEmailReportData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);

  const openDownloadModaltwo = (weldNumbers, boundary, reportData) => {
    setWeldNumberArray(weldNumbers);
    setInputBoundary(boundary);
    setIsModalVisibletwo(true);
    setOpenShareEmailReportData(reportData);
  };

  const closeDownloadModaltwo = () => {
    setIsLoading(false);
    setIsModalVisibletwo(false);
    setEmails([]);
    emailFormtwo.resetFields();
  };

  const PqrreportLink = useSelector(
    (state) => state.pqrdataReducer?.pqrReportLinkData?.recordList
  );

  let excludedTlogsArray = [];

  if (PqrreportLink?.[0]?.excluded_tlogs_key) {
    try {
      // Parse the excluded_tlogs_key JSON string
      const excludedTlogsKeyObject = JSON.parse(
        PqrreportLink[0].excluded_tlogs_key
      );

      // Check if excluded_tlogs_key array has at least one item
      if (excludedTlogsKeyObject?.excluded_tlogs_key?.[0]) {
        // Destructure to get values
        const { pass_name, torch, weld_number, excluded_tlogs } =
          excludedTlogsKeyObject.excluded_tlogs_key[0];

        excludedTlogsArray = [
          {
            pass_name,
            torch,
            weld_number,
            excluded_tlogs,
          },
        ];
      } else {
        console.warn("excluded_tlogs_key array is empty or undefined.");
      }
    } catch (error) {
      console.error("Error parsing excluded_tlogs_key:", error);
    }
  } else {
    console.warn("PqrreportLink[0].excluded_tlogs_key is undefined.");
  }

  const handleDownloadtwo = () => {
    if (!selectedProjectId) {
      notification.error({
        message: "Project ID is missing",
      });
      return;
    }

    setIsLoading(true);

    const { max_min_data, selected_columns } = openShareEmailReportData || {};

    const parsedMaxMinData = JSON.parse(max_min_data || "{}")?.max_min_data;
    const selectedColumns = JSON.parse(
      selected_columns || "{}"
    )?.selected_columns;

    const payload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: inputBoundary,
      email_address: emails.join(","),
      ...(parsedMaxMinData && { items: parsedMaxMinData }),
      ...(selectedColumns && { columns_add: selectedColumns }),
      excluded_tlogs: excludedTlogsArray,
    };

    dispatch({
      type: FETCH_PQRDATA_REPORT_SAGA,
      payload,
      cb: () => {
        closeDownloadModaltwo();
      },
      error: closeDownloadModaltwo,
    });
  };

  return (
    <>
      {Loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <>
            {tableLimitData && tableLimitData.length > 0 && hasJobNumbers ? (
              <>
                <div className="pqr-report-div">
                  <Select
                    mode="multiple"
                    placeholder="Select Weld Numbers"
                    value={selectedWeldNumbers}
                    onChange={handleWeldNumberChange}
                    allowClear
                    className="pqr-report"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {weldnumber.map((number) => (
                      <Option key={number} value={number}>
                        {number}
                      </Option>
                    ))}
                  </Select>
                  {selectedWeldNumbers.length > 0 && (
                    <Button
                      type="primary"
                      className="pqrButton"
                      onClick={() => openSelectedDiv()}
                    >
                      PQR Report
                    </Button>
                  )}
                  <div className="selected-weld-numbers">
                    {selectedWeldNumbers.map((number) => (
                      <Tag
                        key={number}
                        closable
                        onClose={() => handleClose(number)}
                        className="options-show"
                      >
                        {number}
                      </Tag>
                    ))}
                  </div>
                </div>

                {reportGeneratedData && reportGeneratedData.length > 0 ? (
                  <div className="header">
                    <div>Sr.no</div>
                    <div>Weld Number</div>
                    <div>PassView Report</div>
                    <div>Tolerance</div>
                    <div>PQR Report</div>
                  </div>
                ) : null}
                {reportGeneratedData && reportGeneratedData.length > 0
                  ? reportGeneratedData.map((report, index) => (
                      <div className="pqr-gen-div" key={report.id}>
                        <div className="row">
                          <div className="sr_no">{index + 1}</div>
                          <div className="weld-numbers">
                            {report.weld_numbers
                              .split(",")
                              .map((weldNumber, index) => (
                                <span className="weld-number" key={index}>
                                  <span className="weld-bg">
                                    {weldNumber.trim()}
                                  </span>{" "}
                                </span>
                              ))}
                          </div>
                          <div className="document-icons">
                            {report.weld_numbers
                              .split(",")
                              .map((weldNumber, index) => {
                                const weldReport = report.weldReports.find(
                                  (wr) => wr.weld_number === weldNumber.trim()
                                );
                                return weldReport ? (
                                  <span className="document-icon" key={index}>
                                    <Tooltip
                                      title="Download PassView Report"
                                      placement="top"
                                    >
                                      <a
                                        href={weldReport.report_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={pdf}
                                          alt="Pdf"
                                          className="Passvie-pdf"
                                        />
                                      </a>
                                    </Tooltip>
                                  </span>
                                ) : (
                                  <span className="document-icon">
                                    <Tooltip
                                      title="PassView Report has not been generated yet."
                                      placement="top"
                                    >
                                      <img
                                        src={pdfdisable}
                                        alt="Pdf-disabled"
                                        className="Pdf-disabled"
                                      />
                                    </Tooltip>
                                  </span>
                                );
                              })}
                          </div>
                          <div className="percentage">
                            <span className="boundary-percetage">
                              {report.boundary}%
                            </span>
                          </div>
                          <div className="action-icons">
                            <span
                              className="viewanddownloadeye"
                              onClick={() =>
                                handlePQRGenerayedReportClick(
                                  report.weld_numbers
                                    .split(",")
                                    .map((num) => num.trim()),
                                  report.boundary
                                )
                              }
                            >
                              <Tooltip title="View PQR Report" placement="top">
                                <EyeOutlined />
                              </Tooltip>
                            </span>
                            {report.pqr_report_url ? (
                              <Tooltip
                                title="Download PQR Report"
                                placement="top"
                              >
                                <span
                                  className="viewanddownload"
                                  onClick={() =>
                                    window.open(report.pqr_report_url, "_blank")
                                  }
                                >
                                  <DownloadOutlined />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="PQR Report has not been generated yet"
                                placement="top"
                              >
                                <span className="viewanddownload-disable">
                                  <DownloadOutlined />
                                </span>
                              </Tooltip>
                            )}
                            {report.pqr_report_url ? (
                              <Tooltip title="Share PQR Report" placement="top">
                                <span
                                  className="share-pqr-report"
                                  onClick={() =>
                                    openDownloadModaltwo(
                                      report.weld_numbers
                                        .split(",")
                                        .map((num) => num.trim())
                                        .join(","),
                                      report.boundary,
                                      report
                                    )
                                  }
                                >
                                  <SendOutlined />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="PQR Report has not been generated yet"
                                placement="top"
                              >
                                <span className="share-pqr-report-disable">
                                  <SendOutlined />
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </>
            ) : (
              <div className="slog-NoData">
                <p>
                  PQR Report cannot be generated as Weld parameters are missing
                  for this project.
                </p>
              </div>
            )}
          </>
          <Modal
            title={t("sLogData.shareReport")}
            open={isModalVisibletwo}
            onCancel={closeDownloadModaltwo}
            className="report-modal-style"
            width={600}
            footer={[
              <Button key="cancel" onClick={closeDownloadModaltwo}>
                {t("common.cancel")}
              </Button>,
              <Button
                key="download"
                type="primary"
                onClick={handleDownloadtwo}
                disabled={emails.length === 0}
              >
                {isLoading ? "Sending" : "Send"}
              </Button>,
            ]}
          >
            <EmailForm emails={emails} setEmails={setEmails} />
          </Modal>
        </>
      )}
    </>
  );
};

export default PQRReportContent;
