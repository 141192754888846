import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ExclamationIcon from "../../assets/exclamation.svg";
import { commonConstants } from "../../constants";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { startTlogsLoader } from "../../redux/tLogData/actions";
import { FETCH_TLOGS_SAGA } from "../../redux/tLogData/type";
import CommonLoader from "../Loaders/loader";
import { tlogHelper } from "../../helpers/tlog/tlog-helper";

const TlogTableView = ({
  s_deviceid_event,
  station_number,
  type,
  zone,
  weld_number,
  tlodVisibleColumns,
  setSelectedRowKeys,
  selectedRowKeys = [],
  boundary,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.tlogsReducer);
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);
  const loader = reducer?.isTlogsLoading;
  const location = useLocation();
  const [weldParamsShowColumns, setWeldParamsShowColumns] = useState([]);

  const { projectReducer } = useSelector((state) => state);
  const { weld_no, Bugtype, deviceid } = location.state || {};

  const [reportColumns, setReportColumns] = useState(
    logDataHelperFunctions.sampleReportColumns
  );

  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  const hasData = weld_no && Bugtype && deviceid;

  console.log(tlodVisibleColumns, "tlodVisibleColumns");

  useEffect(() => {
    if (reducer?.tlogsList?.weldParams?.length > 0) {
      let allMappedValues = reducer?.tlogsList?.weldParams
        ?.map((ite) => {
          try {
            let params = ite.parameter_check_list || {};
            let enabledKeys = [];
            for (let key of Object.keys(params)) {
              if (params[key]) {
                enabledKeys.push(key);
              }
            }

            return {
              job_number: ite.job_number,
              enabledKeys:
                enabledKeys?.length > 0
                  ? enabledKeys
                  : logDataHelperFunctions.defaultParamsValues,
            };
          } catch (e) {
            console.log(e);
            return null;
          }
        })
        .filter((ite) => ite != null);

      setWeldParamsShowColumns(allMappedValues);
    }
  }, [reducer?.tlogsList?.weldParams]);

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );
  const tlogData = reducer?.tlogsList?.allTlogs?.recordList || [];
  const tableLimitData = reducer?.tlogsList?.tableLimitData?.recordList || [];

  useEffect(() => {
    if (selectedProject) {
      const params = {
        // skip: 0,
        project_id: selectedProjectId,
        project_name: selectedProjectName,
        deviceid_event: s_deviceid_event,
        weld_number: weld_number,
        zone: zone,
        type: type,
      };

      dispatch(startTlogsLoader());
      dispatch({ type: FETCH_TLOGS_SAGA, payload: params });
    }
  }, [
    dispatch,
    selectedProject,
    selectedProjectName,
    s_deviceid_event,
    selectedProjectId,
    weld_number,
    zone,
    type,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedProject && hasData) {
      const params = {
        project_id: selectedProjectId,
        project_name: selectedProjectName,
        deviceid_event: deviceid,
        weld_number: weld_no,
        type: Bugtype,
      };

      dispatch(startTlogsLoader());
      dispatch({ type: FETCH_TLOGS_SAGA, payload: params });
    }
  }, [
    dispatch,
    selectedProject,
    s_deviceid_event,
    selectedProjectId,
    weld_number,
    type,
    Bugtype,
  ]);

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
  };

  const hideColumnsStationZero = [
    "tilt",
    "distance",
    "wireSpeed",
    "oscillationWidth",
    "target",
    "horizontalBias",
    "frequency",
    "totalWireConsumed",
    "trueEnergy",
  ];

  const showRangeColumns = [
    "volts",
    "current",
    "wireSpeed",
    "oscillationWidth",
    "trueEnergy",
    "heat",
    "travelSpeed",
  ];

  let mappingColumnTableLimit = tlogHelper.mappingColumnTableLimit;

  const getMaxMinInRangeFilter = ({
    avgTlogItem,
    tableLimitData,
    currentValue,
    type,
    column,
    currentColumnProjectParam,
  }) => {
    if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
      return {
        doesExist: false,
        showColor: "",
      };
    }

    //in case of pqr dont show red circle
    if (boundary) {
      return {
        doesExist: false,
        showColor: "",
      };
    }

    let passNameExtracted = "";
    if (type == "Lead") {
      passNameExtracted = avgTlogItem?.lead_pass_name;
    } else if (type == "Trail") {
      passNameExtracted = avgTlogItem?.trail_pass_name;
    } else {
      passNameExtracted =
        avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;
    }

    if (passNameExtracted.includes("Head")) {
      passNameExtracted = "HEAD";
    }

    let job_number = avgTlogItem?.job_number;

    let allTableLimitZones = [];

    if (job_number) {
      allTableLimitZones = tableLimitData?.filter(
        (tableLimit) => tableLimit.job_number == job_number
      );

      let enabled = logDataHelperFunctions.isColumnEnabledForRedCircle({
        currentColumn: currentColumnProjectParam,
        jobNumber: job_number,
        weldParamsShowColumns: weldParamsShowColumns,
      });

      console.log(
        {
          enabled,
        },
        "enabledenabled"
      );

      if (!enabled) {
        return {
          doesExist: false,
          showColor: "",
        };
      }
    }

    allTableLimitZones = allTableLimitZones?.filter(
      (tableLimit) => tableLimit.zone_name == passNameExtracted
    );

    let allPassNames = allTableLimitZones?.map((ite) => ite.pass_name);

    let uniquePassNames = [...new Set([...allPassNames])];

    if (column == "current") {
      console.log(
        {
          allTableLimitZones,
          job_number,
          column,
          uniquePassNames,
          currentValue,
          isEnabled:
            parseFloat(currentValue) <
              parseFloat(allTableLimitZones?.[0]?.[`${column}_min`]) ||
            parseFloat(currentValue) >
              parseFloat(allTableLimitZones?.[0]?.[`${column}_max`]),
        },
        "allTableLimitZonesallTableLimitZones"
      );
    }

    if (uniquePassNames?.length == 0) {
      return {
        doesExist: false,
        showColor: "",
      };
    } else {
      return {
        doesExist: true,
        showColor:
          allTableLimitZones?.[0]?.[`${column}_min`] &&
          allTableLimitZones?.[0]?.[`${column}_max`]
            ? parseFloat(currentValue) <
                parseFloat(allTableLimitZones?.[0]?.[`${column}_min`]) ||
              parseFloat(currentValue) >
                parseFloat(allTableLimitZones?.[0]?.[`${column}_max`])
              ? "-color"
              : ""
            : "",
      };
    }
  };

  const getRangeColumn = ({ avgTlogItem, tableLimitData, type, column }) => {
    if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
      return {
        doesExist: false,
        showColor: "",
      };
    }

    let passNameExtracted = "";
    if (type == "Lead") {
      passNameExtracted = avgTlogItem?.lead_pass_name;
    } else if (type == "Trail") {
      passNameExtracted = avgTlogItem?.trail_pass_name;
    } else {
      passNameExtracted =
        avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;
    }
    if (passNameExtracted.includes("Head")) {
      passNameExtracted = "HEAD";
    }

    let job_number = avgTlogItem?.job_number;

    let allTableLimitZones = [];

    if (job_number) {
      allTableLimitZones = tableLimitData?.filter(
        (tableLimit) => tableLimit.job_number == job_number
      );
    }

    allTableLimitZones = allTableLimitZones?.filter(
      (tableLimit) => tableLimit.zone_name == passNameExtracted
    );

    // console.log(
    //   {
    //     allTableLimitZones,
    //     passNameExtracted,
    //   },
    //   "passNameExtracted"
    // );

    let allPassNames = allTableLimitZones?.map((ite) => ite.pass_name);

    let uniquePassNames = [...new Set([...allPassNames])];

    if (uniquePassNames?.length == 0) {
      return {
        doesExist: false,
        showColor: "",
      };
    } else {
      return {
        doesExist: true,
        showColor:
          allTableLimitZones?.[0]?.[`${column}_min`] &&
          allTableLimitZones?.[0]?.[`${column}_max`]
            ? `[${tlogHelper.rangeCalculation(
                allTableLimitZones?.[0]?.[`${column}_min`],
                column,
                reportColumns
              )} -  ${tlogHelper.rangeCalculation(
                allTableLimitZones?.[0]?.[`${column}_max`],
                column,
                reportColumns
              )}]`
            : "",
      };
    }
  };

  const getValuesForColumns = ({
    leadKeyName,
    trailKeyName,
    column,
    type,
    stationNumber,
    tableLimitData,
    tlog,
    isOnlyLeadFilter,
    currentColumnProjectParam,
  }) => {
    const getFilterResult = (keyName) => {
      return (
        tlog[keyName] +
        getMaxMinInRangeFilter({
          avgTlogItem: tlog,
          column: column,
          type: type,
          currentValue: tlog[keyName],
          tableLimitData: tableLimitData,
          currentColumnProjectParam: currentColumnProjectParam,
        })?.showColor
      );
    };

    if (isOnlyLeadFilter) {
      return type === "Lead"
        ? getFilterResult(leadKeyName)
        : getFilterResult(trailKeyName);
    }

    return type === "Lead" || stationNumber === "0"
      ? getFilterResult(leadKeyName)
      : getFilterResult(trailKeyName);
  };

  const getPassName = (tlog, stationNumber, type, tableLimit) => {
    let passName =
      stationNumber == "0"
        ? "Root IWM"
        : type == "Lead" || stationNumber == "0"
        ? tlog.lead_pass_name_actual
        : tlog.trail_pass_name_actual;
    return passName ? passName : tableLimit.length == 0 ? "-" : "!";
  };

  const getZoneName = (tlog, stationNumber, type) => {
    let zoneName =
      type == "Lead" || stationNumber == "0"
        ? tlog.lead_pass_name
        : tlog.trail_pass_name;

    return zoneName ? zoneName : "!";
  };

  let stationNumber = station_number;
  let tlogParsedData = tlogData?.map((tlog, index) => {
    return {
      sl_no: index + 1,
      status:
        type == "Lead" || type == ""
          ? logDataHelperFunctions.getIconForStatus(tlog.lead_status)
          : logDataHelperFunctions.getIconForStatus(tlog.trail_status),
      event: tlog.deviceid_t_event,
      time: logDataHelperFunctions.formatWeldStartTime(tlog.time, "", true),
      tilt: tlog.tilt,
      pass: getPassName(tlog, stationNumber, type, tableLimitData),
      zone: getZoneName(tlog, stationNumber, type),
      distance: tlog.distance,
      travelSpeed: getValuesForColumns({
        column: "travel_speed",
        leadKeyName: "travel_speed",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        trailKeyName: "travel_speed",
        type,
        isOnlyLeadFilter: false,
        currentColumnProjectParam:
          logDataHelperFunctions.allParamsValues.travelSpeed,
      }),
      volts: getValuesForColumns({
        column: "volts",
        leadKeyName: "lead_volts",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        trailKeyName: "trail_volts",
        type,
        isOnlyLeadFilter: false,
        currentColumnProjectParam:
          logDataHelperFunctions.allParamsValues.arcVolts,
      }),
      current: getValuesForColumns({
        column: "current",
        leadKeyName: "lead_amps",
        trailKeyName: "trail_amps",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        type,
        isOnlyLeadFilter: false,
        currentColumnProjectParam:
          logDataHelperFunctions.allParamsValues.arcCurrent,
      }),
      wireSpeed: getValuesForColumns({
        column: "wire_speed",
        leadKeyName: "lead_wire_speed",
        trailKeyName: "trail_wire_speed",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        type,
        isOnlyLeadFilter: true,
        currentColumnProjectParam:
          logDataHelperFunctions.allParamsValues.wireSpeed,
      }),
      oscillationWidth: getValuesForColumns({
        column: "oscillation_width",
        leadKeyName: "lead_oscillate_width",
        trailKeyName: "trail_oscillate_width",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        type,
        isOnlyLeadFilter: true,
        currentColumnProjectParam:
          logDataHelperFunctions.allParamsValues.oscillationWidth,
      }),
      target: type == "Lead" ? tlog.lead_target : tlog.trail_target,
      horizontalBias:
        type == "Lead" ? tlog.lead_horizontal_bias : tlog.trail_horizontal_bias,
      frequency: type == "Lead" ? tlog.lead_frequency : tlog.trail_frequency,
      totalWireConsumed:
        type == "Lead"
          ? tlog.lead_total_wire_consumed
          : tlog.trail_total_wire_consumed,
      trueEnergy: getValuesForColumns({
        column: "heat",
        leadKeyName: "lead_heat",
        trailKeyName: "trail_heat",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        type,
        isOnlyLeadFilter: true,
        currentColumnProjectParam:
          logDataHelperFunctions.allParamsValues.trueEnergy,
      }),
      heat: getValuesForColumns({
        column: "calculated_heat",
        leadKeyName: "lead_calculated_heat",
        trailKeyName: "trail_calculated_heat",
        stationNumber: stationNumber,
        tableLimitData: tableLimitData,
        tlog: tlog,
        type,
        isOnlyLeadFilter: false,
        currentColumnProjectParam: logDataHelperFunctions.allParamsValues.heat,
      }),
    };
  });

  let isHavingHead = tlogParsedData.filter((ite) =>
    ite.zone.toLowerCase().includes("head")
  );

  if (isHavingHead?.length) {
    tlogParsedData = tlogParsedData
      .sort((a, b) => {
        if (a.zone < b.zone) return -1;
        if (a.zone > b.zone) return 1;
        return 0;
      })
      .map((ite, index) => {
        return {
          ...ite,
          sl_no: index + 1,
        };
      });
  }

  console.log(tlogParsedData, "tlogParsedData");

  tlogParsedData = tlogParsedData?.map((ite) => {
    return tlogHelper.mapTlogWithUnitConversion(ite, reportColumns);
  });
  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;

    if (screenHeight >= 832 && screenHeight <= 956) {
      return 520;
    } else {
      return 320;
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("Newly selected rows:", newSelectedRowKeys); // Log for debugging

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const PqrreportLink = useSelector(
    (state) => state.pqrdataReducer?.pqrReportLinkData?.recordList
  );
  const excludedTlogsKey = PqrreportLink?.[0]?.excluded_tlogs_key || [];

  // const excludedTlogsData = JSON.parse(excludedTlogsKey) || [];
  let excludedTlogsData;

  try {
    excludedTlogsData = excludedTlogsKey ? JSON.parse(excludedTlogsKey) : [];
  } catch (error) {
    console.error("Failed to parse excludedTlogsKey:", error);
    excludedTlogsData = [];
  }

  const excludedTlogsValues = Array.isArray(
    excludedTlogsData?.excluded_tlogs_key?.[0]?.excluded_tlogs
  )
    ? excludedTlogsData.excluded_tlogs_key[0].excluded_tlogs
    : [];
  console.log("excludedTlogsValues", excludedTlogsValues);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record) => ({
    //   disabled: excludedTlogsValues.includes(record.event),
    // }),
    columnTitle: () => <span>Remove Row</span>,
    renderCell: (checked, record, index, originNode) => {
      return (
        <Tooltip
          title={
            excludedTlogsValues.includes(record.event)
              ? "value is used already"
              : ""
          }
        >
          {originNode}
        </Tooltip>
      );
    },
  };

  return (
    <div>
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <>
            {tlogParsedData.length > 0 ? (
              <>
                <Table
                  rowSelection={
                    hasData
                      ? {
                          ...rowSelection,
                          columnWidth: 100,
                        }
                      : undefined
                  }
                  className={
                    hasData
                      ? `zoneview-table tlogtable-style-hasdata`
                      : `zoneview-table tlogtable-style`
                  }
                  // className="zoneview-table tlogtable-style"
                  dataSource={tlogParsedData}
                  pagination={paginationOptions}
                  scroll={{ x: 2500, y: getScrollHeight() }}
                  bordered
                  rowKey={(record) => record.event}
                >
                  {tlogHelper
                    .mapTlogColumns(tlodVisibleColumns, reportColumns, hasData)
                    ?.filter((ite) =>
                      stationNumber == "0"
                        ? hideColumnsStationZero.includes(ite.dataIndex)
                          ? null
                          : ite
                        : ite
                    )
                    ?.filter((ite) => ite != null)
                    ?.map((column) => {
                      return (
                        <Table.Column
                          key={column.key}
                          title={
                            showRangeColumns?.includes(column.dataIndex)
                              ? column.title +
                                " " +
                                getRangeColumn({
                                  avgTlogItem: tlogData[0],
                                  column:
                                    mappingColumnTableLimit[column.dataIndex],
                                  tableLimitData: tableLimitData,
                                  type: type,
                                })?.showColor
                              : column.title
                          }
                          dataIndex={column.dataIndex}
                          width={column.width || 100}
                          render={(text) => {
                            if (column.dataIndex === "event" && text) {
                              const eventText = text.split("_")[1];
                              return eventText || text;
                            }
                            if (column.dataIndex == "status") {
                              return text;
                            }

                            if (
                              column.dataIndex == "pass" ||
                              column.dataIndex == "zone"
                            ) {
                              return text == "!" ? (
                                <img src={ExclamationIcon} alt="Exclamation" />
                              ) : (
                                text
                              );
                            }

                            if (text && text?.toString()?.includes("-color")) {
                              return (
                                <span className="red-note">
                                  {text.replace("-color", "")}
                                </span>
                              );
                            }
                            return text;
                          }}
                        />
                      );
                    })}
                </Table>
              </>
            ) : (
              <div className="slog-NoData">
                <p>{t("sLogData.noDataAvailable")}</p>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default TlogTableView;
