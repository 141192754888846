import { Tabs } from "antd";
import React from "react";
import PassZone from "./PassZone";
import ZoneView from "./ZoneView";

const SlogTab = ({
  passVisibleColumns,
  zoneVisibleColumns,
  setActiveTab,
  weld_number,
}) => {
  const tabKeys = {
    PassView: "PassView",
    ZoneView: "ZoneView",
  };

  const tabItems = [
    {
      key: tabKeys.PassView,
      label: "Pass View",
      children: (
        <PassZone
          visibleColumns={passVisibleColumns}
          weld_number={weld_number}
        />
      ),
    },
    {
      key: tabKeys.ZoneView,
      label: "Zone View",
      children: (
        <ZoneView
          visibleColumns={zoneVisibleColumns}
          weld_number={weld_number}
        />
      ),
    },
  ];

  const handleTabChange = (key) => {
    if (key === tabKeys.PassView) {
      setActiveTab("1");
    } else if (key === tabKeys.ZoneView) {
      setActiveTab("2");
    }
  };

  return (
    <Tabs
      defaultActiveKey={tabKeys.PassView}
      items={tabItems}
      className="custom-tab"
      destroyInactiveTabPane={true}
      onChange={handleTabChange}
    />
  );
};

export default SlogTab;
